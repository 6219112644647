import React from 'react';
import { applyEditModeAttr } from '../Shared/Common/Helpers';
import StandardBlockModel from './Models/StandardBlockModel.interface';
import { styled, theme } from '../Theme';
import IconLink from '../Shared/IconLink/IconLink';
import GridItem from '../Shared/Grid/GridItem';
import XhtmlComponent from '../Shared/XhtmlComponent/XhtmlComponent';
import useMedia from '../Shared/Hooks/useMedia';
import { StyleOrStyleArray } from '@glitz/type';
import { media, pseudo } from '@glitz/core';
import { LinkType } from '../Enums/LinkType.enum';
import ImageRatio from '../Shared/Image/ImageRatio';

type PropType = {
  content: StandardBlockModel;
};

function StandardBlock({
  content: {
    heading,
    image,
    mobileImage,
    introText,
    bodyText,
    link,
    inEditMode,
    layout,
    imageRight,
    whiteText,
    extraMargin,
  },
}: PropType) {
  const isMobile = useMedia(theme.mediaQuery.mediaMaxMedium);
  const headingStyle: StyleOrStyleArray = {
    color: whiteText ? theme.white : theme.blueDark,
  };

  const isFullRowBlock = layout === '1/1' || layout === 'FullWidth';

  const { text: linkText, href = '', ...restLink } = link || {};

  const gridItemChildStyle: StyleOrStyleArray = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  };

  const renderLink = (
    <>
      {link && (
        <LinkWrapper>
          <IconLink
            href={href}
            {...restLink}
            {...applyEditModeAttr(inEditMode && 'Link')}
            whiteText={whiteText}
            type={LinkType.Arrow}
            fullPageReload={link.fullPageReload}
          >
            {linkText}
          </IconLink>
        </LinkWrapper>
      )}
    </>
  );

  return (
    <GridItem
      layout={layout}
      extraMargin={extraMargin}
      childStyle={gridItemChildStyle}
    >
      <>
        <Root data-blocklayout={layout}>
          <ImageArea
            {...applyEditModeAttr(inEditMode && 'Image')}
            data-imgright={imageRight}
            data-blocklayout={layout}
          >
            {image && (
              <ImageRatio
                src={
                  isMobile && mobileImage ? mobileImage.src : image && image.src
                }
              />
            )}
          </ImageArea>
          <TextContainer data-blocklayout={layout} data-imgright={imageRight}>
            <IntroText {...applyEditModeAttr(inEditMode && 'IntroText')}>
              {introText}
            </IntroText>
            <Heading
              {...applyEditModeAttr(inEditMode && 'Heading')}
              css={headingStyle}
            >
              {heading}
            </Heading>
            <BodyText>
              <XhtmlComponent
                {...applyEditModeAttr(inEditMode && 'BodyText')}
                content={bodyText}
                className="block-text"
              />
            </BodyText>
            {isFullRowBlock && renderLink}
          </TextContainer>
        </Root>
        {!isFullRowBlock && <OuterLinkWrapper> {renderLink}</OuterLinkWrapper>}
      </>
    </GridItem>
  );
}

export default StandardBlock;

const Root = styled.div({
  flexWrap: 'wrap',
  display: 'flex',
  width: '100%',
  ...pseudo(
    [
      ':nth-child(n)[data-blocklayout="1/1"]',
      ':nth-child(n)[data-blocklayout="FullWidth"]',
    ],
    {
      ...media(theme.mediaQuery.mediaMinMedium, {
        flexWrap: 'nowrap',
      }),
    }
  ),
  ...pseudo([':nth-child(n)[data-blocklayout="FullWidth"]'], {
    flexWrap: 'nowrap',
    ...media(theme.mediaQuery.mediaMaxMedium, {
      flexWrap: 'wrap',
      padding: { x: theme.none },
    }),
  }),
});

const ImageArea = styled.div({
  position: 'relative',
  alignSelf: 'center',
  order: 0,
  ...media(theme.mediaQuery.mediaMaxMedium, {
    marginBottom: theme.spacing(4),
  }),
  ...media(theme.mediaQuery.mediaMaxGreat, {
    padding: { x: theme.spacing(4) },
  }),
  ...pseudo([':nth-child(n)[data-imgright="true"]'], {
    ...media(theme.mediaQuery.mediaMinMedium, {
      order: 1,
    }),
  }),

  ...pseudo([':nth-child(n)[data-blocklayout="FullWidth"]'], {
    ...media(theme.mediaQuery.mediaMinMedium, {
      flex: { grow: 0, shrink: 0, basis: '50%' },
    }),
    ...media(theme.mediaQuery.mediaMinLarge, {
      flex: { grow: 0, shrink: 0, basis: '60%' },
    }),
    ...media(theme.mediaQuery.mediaMaxMedium, {
      flex: { grow: 0, shrink: 0, basis: '100%' },
    }),
  }),
  ...pseudo([':nth-child(n)[data-blocklayout="1/1"]'], {
    ...media(theme.mediaQuery.mediaMinMedium, {
      flex: { grow: 0, shrink: 0, basis: '50%' },
    }),
    ...media(theme.mediaQuery.mediaMaxMedium, {
      flex: { grow: 0, shrink: 0, basis: '100%' },
    }),
    ...pseudo([':nth-child(n)[data-imgright="false"]'], {
      ...media(theme.mediaQuery.mediaMinLarge, {
        padding: { x: theme.spacing(0) },
        color: 'red',
      }),
    }),
  }),
  ...pseudo(
    [
      ':nth-child(n)[data-blocklayout="1/2"]',
      ':nth-child(n)[data-blocklayout="1/3"]',
      ':nth-child(n)[data-blocklayout="1/4"]',
    ],
    {
      flex: { grow: 0, shrink: 0, basis: '100%' },
      ...media(theme.mediaQuery.mediaMinMedium, {
        padding: { y: theme.spacing(3) },
      }),
    }
  ),
});

const TextContainer = styled.div({
  alignSelf: 'center',
  display: 'flex',
  alignItems: 'baseline',
  flexDirection: 'column',
  padding: { top: theme.spacing(5), bottom: theme.spacing(4) },
  width: '100%',
  ...pseudo([':nth-child(n)[data-blocklayout="FullWidth"]'], {
    ...media(theme.mediaQuery.mediaMinLarge, {
      padding: { x: theme.spacing(16), y: theme.spacing(11) },
      flex: { grow: 0, shrink: 0, basis: '40%' },
      maxWidth: '40%',
    }),
    ...media(theme.mediaQuery.mediaMinMedium, {
      padding: { left: theme.spacing(8), y: theme.spacing(11) },
      flex: { grow: 0, shrink: 0, basis: '50%' },
      maxWidth: '50%',
    }),
    ...media(theme.mediaQuery.mediaMaxMedium, {
      padding: { x: theme.spacing(4) },
      maxWidth: '100%',
    }),
  }),
  ...pseudo([':nth-child(n)[data-blocklayout="1/1"]'], {
    ...media(theme.mediaQuery.mediaMinLarge, {
      padding: { left: theme.spacing(16), y: theme.spacing(11) },
      maxWidth: '50%',
    }),
    ...media(theme.mediaQuery.mediaMaxMedium, {
      padding: {
        x: theme.spacing(4),
      },
    }),
    ...media(theme.mediaQuery.mediaMinMedium, {
      padding: {
        left: theme.spacing(8),
        right: theme.spacing(4),
        y: theme.spacing(11),
      },
      flex: { grow: 0, shrink: 0, basis: '50%' },
      maxWidth: '50%',
    }),
    ...pseudo([':nth-child(n)[data-imgright="true"]'], {
      ...media(theme.mediaQuery.mediaMinMedium, {
        padding: { xy: theme.spacing(4) },
      }),
      ...media(theme.mediaQuery.mediaMinGreat, {
        padding: { left: theme.none },
      }),
    }),
  }),
  ...media(theme.mediaQuery.mediaMaxVerySmall, {
    padding: { y: theme.none },
  }),
  ...pseudo(
    [
      ':nth-child(n)[data-blocklayout="1/2"]',
      ':nth-child(n)[data-blocklayout="1/3"]',
      ':nth-child(n)[data-blocklayout="1/4"]',
    ],
    {
      ...media(theme.mediaQuery.mediaMaxGreat, {
        padding: { x: theme.spacing(4) },
      }),
    }
  ),
});

const Heading = styled.h3({
  font: { size: theme.eta, weight: theme.fontWeight.bold },
  color: theme.blueDark,
  letterSpacing: theme.letterSpacing.moreWide,
  lineHeight: theme.lineHeight.none,
  marginBottom: theme.spacing(1.5),
  ...media(theme.mediaQuery.mediaMaxMedium, {
    fontSize: theme.zeta,
    marginBottom: theme.spacing(2),
  }),
  ...media(theme.mediaQuery.mediaMaxVerySmall, {
    fontSize: theme.epsilon,
  }),
});

const IntroText = styled.p({
  font: { size: theme.alpha, weight: theme.fontWeight.normal },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.medium,
  marginBottom: theme.spacing(1),
});

const BodyText = styled.div({
  marginBottom: theme.spacing(4),
});

const LinkWrapper = styled.div({
  width: 'fit-content',
  marginLeft: 'auto',
  marginTop: 'auto',
  color: theme.blueDark,
});

const OuterLinkWrapper = styled.div({
  padding: { right: theme.spacing(4) },
});
